<template>
  <div id="app">
    <b-navbar
      :toggleable="updateExists ? 'lg' : 'md'"
      type="dark"
      :variant="navbarVariant"
      class="gid-header"
      :class="{ 'flex-wrap': !updateExists }"
    >
      <back-button class="mr-2" />
      <router-link
        to="/"
        class="d-flex flex-wrap align-items-center justify-content-center"
      >
        <b-navbar-brand to="/" class="mr-auto">
          <img
            v-if="brandLogo"
            :src="brandLogo"
            alt="GID BRAND PORTAL"
            class="gid-logo_img"
          />
          <template v-else>GID BRAND PORTAL</template>
        </b-navbar-brand>
        <b-badge
          v-if="environment !== 'production'"
          class="mx-2"
          variant="warning"
        >
          {{ environment }}
        </b-badge>
      </router-link>
      <b-navbar-toggle
        target="nav_collapse"
        v-if="!updateExists"
      ></b-navbar-toggle>
      <b-collapse v-if="isAuthenticated" is-nav id="nav_collapse">
        <b-navbar-nav>
          <b-nav-item
            v-for="(item, index) in navItemsFiltered"
            :key="index"
            :to="{ name: item }"
            :class="{ active: isQuoteApprovalPage(item) }"
          >
            {{ $t(`nav.${item}`) }}
            <img
              v-if="showNotifyProposalsIcon(item)"
              src="@/assets/icons/notify_icon.svg"
              alt="approval-jobs"
              class="warning-icon"
            />
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown
            v-if="isAuthenticated"
            is-nav
            id="nav_collapse"
            right
          >
            <template #button-content>
              <i class="material-icons align-middle">person</i>
            </template>
            <b-nav-item v-if="showSettings" :to="{ name: 'settings' }">
              <span class="profile-menu-option">{{ $t(`nav.settings`) }}</span>
            </b-nav-item>
            <b-nav-item @click="logout">
              <span class="profile-menu-option">{{ $t('nav.logout') }}</span>
            </b-nav-item>
          </b-nav-item-dropdown>
          <b-nav-item v-else v-e2e:logoutLink @click="logout">
            {{ $t('nav.logout') }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <b-collapse v-else is-nav id="nav_collapse">
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-e2e:loginLink to="/login">
            {{ $t('nav.login') }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <refresh-button v-if="updateExists" :onRefresh="refreshApp" />
    </b-navbar>

    <b-spinner v-if="hasAccess === null" variant="secondary" class="m-2" />
    <router-view v-else-if="hasAccess" />
    <div v-else class="text-center">
      <h4 class="m-5">{{ $t('no-access.title') }}</h4>
      <p v-if="isAuthenticated">{{ $t('no-access.description') }}</p>
      <i18n v-else path="no-access.login" tag="p" class="mb-0">
        <router-link slot="login" to="/login">
          {{ $t('no-access.login-link') }}
        </router-link>
      </i18n>
    </div>

    <add-to-home-screen app-name="GID Brand" />
    <offline />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { PURGE_AUTH } from '@gid/vue-common/store/auth.module/types';
import { checkRouteAccess } from '@gid/vue-common/store/auth.module';
import update from '@gid/vue-common/components/mixins/update';
import { getEnv } from '@gid/vue-common/utils';
import {
  AccountProfileEnum,
  ContactProfileEnum,
  UserRoleEnum,
} from '@gid/models';
import axios from 'axios';
import { SET_PENDING_PROPOSAL_MENU_ITEM } from '@/store/modules/mainModule/types';

export default {
  mixins: [update],
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'contactProfiles',
      'user',
      'environment',
      'access_token',
      'hasPendingProposals',
      'shouldShowProposalMenuItems',
    ]),
    navbarVariant() {
      if (this.user?.profiles?.includes('readonly')) {
        return 'readonly';
      } else if (this.environment === 'production') {
        return 'primary';
      } else {
        return 'staging';
      }
    },
    navItems() {
      const routes = [
        'job-create',
        'upload',
        'jobs',
        'projects',
        'invoices',
        // 'dashboard', - Temporary disable as per: https://gidt.atlassian.net/browse/GID-300
      ];
      if (this.isProjectsRelated) {
        if (this.shouldShowProposalMenuItems) {
          routes.splice(1, 0, 'quote-approval-jobs');
        }
      } else {
        routes.splice(1, 0, 'jobs-affiliate');
      }
      return routes;
    },
    brandLogo() {
      return (
        this.user &&
        this.user.account &&
        this.user.account.id &&
        `https://${getEnv('S3_HOST')}/brand/${
          this.user.account.id
        }/upload/assets/forApps`
      );
    },
    hasAccess() {
      return checkRouteAccess({
        route: this.$route,
        user: this.user,
        isAuthenticated: this.isAuthenticated,
      });
    },
    isProjectsRelated() {
      if (
        this.user?.account?.profiles?.includes(AccountProfileEnum.PROJECT_VIEW)
      ) {
        const allowedContactProfiles = [
          ContactProfileEnum.ADMIN,
          ContactProfileEnum.REPORTING,
          ContactProfileEnum.PROJECT_MANAGER_GENERIC,
          ContactProfileEnum.PROJECT_MANAGER_DEFAULT,
        ];
        return (
          this.user.contact.profiles.some((p) =>
            allowedContactProfiles.includes(p),
          ) && this.user?.role === UserRoleEnum.BRAND
        );
      }
      return false;
    },
    navItemsFiltered() {
      return this.navItems.filter((item) => {
        const resolved = this.$router.resolve({ name: item });
        if (resolved) {
          return checkRouteAccess({
            route: resolved.route,
            user: this.user,
            isAuthenticated: this.isAuthenticated,
          });
        } else {
          return false;
        }
      });
    },
    isQuoteApprovalPage() {
      return (pageName) =>
        pageName === 'quote-approval-jobs' &&
        this.$route.name === 'quote-approval-jobs';
    },
    showNotifyProposalsIcon() {
      return (pageName) =>
        pageName === 'quote-approval-jobs' && this.hasPendingProposals;
    },
    showSettings() {
      return this.contactProfiles.some((cp) =>
        [
          ContactProfileEnum.PROJECT_MANAGER_DEFAULT,
          ContactProfileEnum.PROJECT_MANAGER_GENERIC,
          ContactProfileEnum.ADMIN,
        ].includes(cp),
      );
    },
  },
  watch: {
    user: function (loggedUser) {
      if (loggedUser?.account?.id) {
        const brandId = loggedUser?.account?.id;
        axios
          .get(
            `data-api/entity/opportunity-option/quote-approval-accessible/${brandId}`,
          )
          .then(({ data }) => {
            const { hasProposalJobs, showProposals } = data;
            this.$store.commit(
              SET_PENDING_PROPOSAL_MENU_ITEM,
              {
                hasPendingProposals: hasProposalJobs,
                showProposalsMenuItem: showProposals,
              },
              { root: true },
            );
          });
      }
    },
  },
  methods: {
    logout() {
      this.$store.commit(PURGE_AUTH);
    },
  },
};
</script>

<style lang="scss">
@import '@gid/vue-common/scss/_variables.scss';
.profile-menu-option {
  color: #04a789;
}
.gid-logo_img {
  max-height: 40px;
  max-width: 200px;
}
.gid-header {
  &.navbar {
    position: sticky;
    top: 0;
    flex-wrap: nowrap;
    z-index: 1032;
    padding: 0.7rem 1rem;
    gap: 0.5rem 1rem;
  }
}
</style>

<style scoped>
.nav-item.active {
  border: 1px solid #e2e2e2;
  border-radius: 2px;
}
</style>
