import moment from '@gid/vue-common/app-settings/moment';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueMoment from 'vue-moment';

import App from './App.vue';

import messages from './i18n/messages';
import router from './router';
import store from './store';
import {
  initVeeValidateRulesWithI18n,
  setVeeValidateLocale,
} from './app/components';
import './app/filters';

import {
  PURGE_AUTH,
  STORE_USER_DATA,
} from '@gid/vue-common/store/auth.module/types';

import '@/assets/scss/styles.scss';
import { e2e } from '@gid/vue-common/app-settings/customDirectives';
import { setupGoogleAnalytics } from '@gid/vue-common/app-settings/googleAnalytics';
import '@gid/vue-common/app-settings/registerServiceWorker';

Vue.directive('e2e', e2e);

Vue.use(VueI18n);
Vue.use(VueMoment, { moment });

const defaultLocale = store.getters.locale;
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  messages,
});
moment.locale(defaultLocale);
setVeeValidateLocale(defaultLocale);
initVeeValidateRulesWithI18n(i18n);

router.beforeEach((to, from, next) => {
  const matchedRoutes = to.matched.slice(0).reverse();
  const allowGuests = matchedRoutes.some((route) => !route.meta?.auth);
  if (!store.getters.isAuthenticated && to.name != 'login' && !allowGuests) {
    next({ name: 'login' });
  } else {
    next();
  }
});

store.subscribe(({ type }) => {
  if (type === PURGE_AUTH) {
    // Go to login page on logout
    router.push({ name: 'login' });
  } else if (type === STORE_USER_DATA) {
    // Set moment locale for consistency
    const locale = store.getters.locale;
    i18n.locale = locale;
    moment.locale(locale);
    setVeeValidateLocale(locale);
  }
});

setupGoogleAnalytics(router);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
