import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';

import RequestPasswordReset from '@gid/vue-common/views/RequestPasswordReset.vue';
import ChangePassword from '@gid/vue-common/views/ChangePassword.vue';
import { AccountProfileEnum } from '@gid/models';

Vue.use(Router);

/* Routes definition specification
 *
 * For each route access is defined via the meta setting
 * `auth`, which could contain `contactProfiles`, `accountProfiles` and `accountStatuses`.
 * If `auth` is present, the user must be authenticated.
 * `contactProfiles` recognizes the following values:
 * - true: requires authenticated user without contact profile restrictions
 * - array of strings: requires authenticated with one of the specified contact profiles
 */

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        auth: {
          contactProfiles: true,
        },
      },
      component: Home,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/request-password-reset',
      component: RequestPasswordReset,
      props: {
        role: 'brand',
      },
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: ChangePassword,
      props: {
        role: 'brand',
      },
    },
    {
      path: '/upload/:state?',
      name: 'upload',
      meta: {
        auth: {
          contactProfiles: ['admin', 'webform editor'],
          accountProfiles: [AccountProfileEnum.EXCEL_UPLOAD],
        },
      },
      component: () => import('./views/Upload.vue'),
      props: true,
    },
    {
      path: '/jobs',
      name: 'jobs',
      meta: {
        auth: {
          contactProfiles: true,
        },
      },
      component: () => import('./views/Jobs.vue'),
    },
    {
      path: '/jobs/:job_id?',
      name: 'jobs',
      meta: {
        auth: {
          contactProfiles: true,
        },
      },
      component: () => import('./views/Jobs.vue'),
      props: true,
    },
    {
      path: '/jobs-affiliate',
      name: 'jobs-affiliate',
      meta: {
        auth: {
          contactProfiles: true,
        },
      },
      component: () => import('./views/JobsAffiliate.vue'),
    },
    {
      path: '/projects',
      name: 'projects',
      meta: {
        auth: {
          contactProfiles: true,
        },
      },
      component: () => import('./views/Projects.vue'),
    },
    {
      path: '/projects/:projectId',
      name: 'project-details',
      meta: {
        auth: {
          contactProfiles: [
            'admin',
            'project manager generic',
            'project manager default',
            'reporting',
          ],
        },
      },
      component: () => import('./views/ProjectDetailsPage.vue'),
      props: true,
    },
    {
      path: '/job-create',
      name: 'job-create',
      meta: {
        auth: {
          contactProfiles: [
            'admin',
            'webform editor',
            'project manager generic',
            'project manager default',
          ],
          accountProfiles: [AccountProfileEnum.WEBFORM],
        },
      },
      component: () => import('@gid/vue-common/components/JobCreate.vue'),
      props: { context: 'brand' },
    },
    {
      path: '/settings',
      name: 'settings',
      meta: {
        auth: {
          contactProfiles: [
            'admin',
            'project manager generic',
            'project manager default',
          ],
        },
      },
      component: () => import('./views/Settings.vue'),
      props: true,
    },
    {
      path: '/invoices',
      name: 'invoices',
      meta: {
        auth: {
          contactProfiles: ['admin', 'accounting'],
        },
      },
      component: () => import('./views/InvoicesList.vue'),
    },
    // Temporary disable as per: https://gidt.atlassian.net/browse/GID-300
    // {
    //   path: '/dashboard',
    //   name: 'dashboard',
    //   meta: {
    //     auth: {
    //       contactProfiles: ['admin', 'accounting', 'reporting'],
    //     },
    //   },
    //   component: () => import('./views/Dashboard.vue'),
    // },
    {
      path: '/approval-jobs',
      name: 'quote-approval-jobs',
      meta: {
        auth: {
          contactProfiles: ['admin', 'accounting', 'reporting'],
        },
      },
      component: () => import('./views/JobsQuoteApproval.vue'),
    },
  ],
});
