import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { storeCommon, storeCommonInit } from '@gid/vue-common/store';
import opportunities_module from '@gid/vue-common/store/opportunities.module';
import mainModule from '@/store/modules/mainModule';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    ...storeCommon.modules,
    opportunities: opportunities_module,
    menuModule: mainModule,
  },
  plugins: [
    ...storeCommon.plugins,
    createPersistedState({
      paths: ['reports'],
    }),
  ],
});

storeCommonInit(store);

export default store;
