import Vue from 'vue';
import store from '../store';

Vue.filter('currency', (value) => {
  const currencyFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
  return currencyFormatter.format(value);
});

Vue.filter('jwtToken', (url) => {
  const token = store.getters.access_token;
  if (!token) {
    return url;
  }
  if (url.includes('?')) {
    return url + '&jwt=' + token;
  } else {
    return url + '?jwt=' + token;
  }
});
